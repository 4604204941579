<div class="main-container" ><!--[ngClass]="{hidden :!authService.isLogged()}" -->
  <app-header
    [ngClass]="['header-container']"
    (toggleEvent)="sidenav.toggle()"
  ></app-header>
  <mat-sidenav-container [ngClass]="['content-container']">
    <mat-sidenav #sidenav mode="over" autosize>
      <div class="menu-container"></div>
    </mat-sidenav>
    <mat-sidenav-content class='wrap1'>
      <!--div class="router-content"  [ngClass]="{hidden : !errorMessage}">
          <app-error-page [errorMessage]="errorMessage"></app-error-page>
      </div-->
      <div id="router-content" class="router-content" >
        <div class="side-menu"><app-sidemenu></app-sidemenu></div> <div class="content"><router-outlet></router-outlet></div>
    </div>
      <div class='wrap'>
        <div class='triangle-background'></div>
        <div class='triangle-background-weak'></div>
        <div class='triangle-background-strong'></div>
        <div class='github'>
        </div>
        <div class='footer-two'></div>
        <div class='footer'></div>

        <app-footer [ngClass]="['footer-container']"></app-footer>
      </div>
      <!--div-- class="router-content" [ngClass]="{hidden : errorMessage}">
          <router-outlet></router-outlet>
      </!--div-->
    </mat-sidenav-content>

  </mat-sidenav-container>
</div>
