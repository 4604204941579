<div class="container">
  <mat-slide-toggle [(ngModel)]="subscribing" >{{'APP.ADMIN.CONNECT_INSCRIPT'|translate}}</mat-slide-toggle>
    <app-dossier-bloc
    [items]="subscribing?subscribe.list:login.list"
    [modifiable]="true"
    [nbColumns]="subscribing?nbColumns:1"
    [translateSuffix]="'APP.LOGIN.'"
    >
  </app-dossier-bloc>
  <button mat-button (click)="connect()">{{(subscribing?'APP.ADMIN.SUBSCRIBE':'APP.ADMIN.LOGIN')|translate}}</button>
  <a (click)="forget()">{{'APP.ADMIN.FORGET'|translate}}</a>
  <!--img class="providers" src="../../../assets/images/google-cloud-logo.png" (click)="loginGoogle()"-->
</div>
